<template>
  <div :class="$style.select_gift_modal">
    <div :class="$style.gift_section">
      <h3 :class="$style.modal_title">
        {{
          isCardOnly && isNotEmbroidery
            ? "メッセージカード設定"
            : giftCards.length > 0 && isNotEmbroidery
            ? "ギフトラッピング・カード設定"
            : "ギフトラッピング設定"
        }}
      </h3>
      <MoleculesConsumerStepper v-if="state.step > 1">
        <AtomsConsumerStepperItem v-if="!isCardOnly" :active="state.step === 2"> タイプ選択 </AtomsConsumerStepperItem>
        <AtomsConsumerStepperItem
          v-if="state.wrappingCheckItem === 'include' && isNotEmbroidery"
          :active="state.step === 3"
        >
          サイズ選択
        </AtomsConsumerStepperItem>
        <AtomsConsumerStepperItem
          v-if="state.wrappingCheckItem !== 'include' && giftMessages.length > 0 && isNotEmbroidery"
          :active="state.step === 4"
        >
          シール選択
        </AtomsConsumerStepperItem>
        <AtomsConsumerStepperItem v-if="giftCards.length > 0 && isNotEmbroidery" :active="state.step === 5">
          カード選択
        </AtomsConsumerStepperItem>
        <AtomsConsumerStepperItem :active="state.step === 9"> 確認 </AtomsConsumerStepperItem>
      </MoleculesConsumerStepper>
      <template v-if="state.step === 1">
        <!-- step1 ラッピング有無選択 -->
        <div v-if="cartDetail" :class="$style.product_box">
          <div :class="$style.right_container">
            <div :class="$style.image_container">
              <input :id="cartDetail.product.name" type="checkbox" checked disabled />
              <AtomsConsumerImage
                :src="cartDetail.sku.main_image"
                :alt="`${cartDetail.product.name} - ${cartDetail.sku.name}${
                  cartDetail.sku.size ? `(${cartDetail.sku.size})` : null
                }の画像`"
                :width="72"
                :height="94"
              />
            </div>
          </div>
          <div :class="$style.detail_container">
            <div :class="$style.name_sku">
              <p :class="$style.name">{{ cartDetail.product.name }}</p>
              <div :class="$style.sku">
                <p :class="$style.material">{{ cartDetail.product.material }}</p>
                <p v-if="!isEGift">
                  <span v-if="cartDetail.sku.size" :class="$style.size"> サイズ: {{ cartDetail.sku.size }} </span>
                  <span v-if="cartDetail.sku.color" :class="$style.color"> / 色: {{ cartDetail.sku.color }} </span>
                </p>
                <p v-else :class="$style.e_gift"> ※ サイズ・色はeギフトを受け取る方に選択してもらいます </p>
              </div>
            </div>
            <div :class="$style.product_price">
              <p v-if="cartDetail.discounted_price === cartDetail.price" :class="$style.price">
                &yen; {{ priceWithComma(cartDetail.reference_unit_price) }}
                <span>(参考税込)</span>
              </p>
              <template v-else>
                <p :class="$style.strike">¥{{ priceWithComma(cartDetail.reference_unit_price) }}</p>
                <p :class="[$style.price, $style.discounted]">
                  &yen; {{ priceWithComma(cartDetail.reference_discounted_unit_price) }}
                  <span>(参考税込)</span>
                </p>
              </template>
            </div>
          </div>
        </div>
        <div :class="$style.warning_message">
          <p> ※ ギフトラッピングは、商品1点ごとに適用されます。複数商品を一つのギフト包装にまとめることはできません </p>
          <p v-if="isNotEmbroidery">
            ※
            複数商品をまとめてラッピングされたい方は「ラッピング資材を購入」を選択のうえ、お客様によるラッピングのご対応をお願いします
          </p>
          <p v-if="isEmbroideryItem">※ 刺繍サービスをご希望の場合は「ラッピングして購入」をお選びください。</p>
        </div>
        <div :class="$style.select_area">
          <AtomsConsumerRadioButton
            v-for="item in wrappingCheckItems"
            :key="item.key"
            :value="item.value"
            name="select_gift"
            :checked="state.wrappingCheckItem"
            @change="(v) => (state.wrappingCheckItem = v)"
          >
            {{ item.key }}
          </AtomsConsumerRadioButton>
        </div>
        <div :class="$style.btn_area">
          <p v-if="VISIBLE_SELF_WRAPPING_PAGE" :class="$style.description">
            ※ラッピング資材を単体でご購入を希望の方は
            <AtomsConsumerLink
              :url="SELF_WRAPPING_PAGE_URL"
              :class="$style.self_wrapping"
              @click="() => emit('cancel')"
            >
              こちら
            </AtomsConsumerLink>
          </p>
          <AtomsConsumerButton variant="primary" block :disabled="!state.wrappingCheckItem" @click="nextStep(1)">
            次へ
          </AtomsConsumerButton>
          <AtomsConsumerButton variant="secondary" block @click="() => emit('cancel')"> 閉じる </AtomsConsumerButton>
        </div>
      </template>
      <template v-else-if="state.step === 2">
        <!-- step2 資材選択 -->
        <h4>ギフトラッピングのタイプを選択してください</h4>
        <div :class="$style.gift_item">
          <div :class="$style.wrapping_list">
            <div
              v-for="(gift, index) in giftWrappings"
              :key="gift.document_id"
              :class="[$style.wrapping, { [$style.is_out_of_stock]: gift.isOutOfStock }]"
              @click="selectedWrapping(index)"
            >
              <div
                :class="[
                  $style.wrapping_img,
                  { [$style.selected]: gift.product?.document_id === state.tempSelectionGift.product_id },
                ]"
              >
                <AtomsConsumerImage :src="gift.img ?? ''" :width="184" :height="245" />
                <AtomsConsumerButton :class="$style.expansion" @click="openGiftImageModal($event, index)">
                  <AtomsConsumerImage src="/images/common/cart/zoom.svg" alt="" :class="$style.zoom" />
                </AtomsConsumerButton>
              </div>
              <div :class="$style.wrapping_info">
                <p :class="$style.title">{{ gift.title }}</p>
                <div v-if="gift.isOutOfStock" :class="$style.out_of_stock">
                  <p :class="$style.label">在庫切れ</p>
                  <p v-if="gift.wrappingSku && gift.wrappingSku.shipping_supplement" :class="$style.supplement">{{
                    gift.wrappingSku.shipping_supplement
                  }}</p>
                </div>
                <p v-if="gift.commission_price != 0" :class="$style.price">
                  <span :class="$style.tax_include">税込</span>&yen;{{
                    gift.commission_price ? gift.commission_price.toLocaleString() : ""
                  }}
                </p>
                <p v-else :class="$style.price">無料</p>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.btn_area">
          <p v-if="VISIBLE_SELF_WRAPPING_PAGE" :class="$style.description">
            ※ラッピング資材を単体でご購入を希望の方は
            <AtomsConsumerLink :url="SELF_WRAPPING_PAGE_URL" :class="$style.self_wrapping"> こちら </AtomsConsumerLink>
          </p>
          <AtomsConsumerButton variant="primary" block :disabled="!state.tempSelectionGift.sku_id" @click="nextStep(2)">
            次へ
          </AtomsConsumerButton>
          <AtomsConsumerButton variant="secondary" block @click="prevStep(2)"> 戻る </AtomsConsumerButton>
        </div>
      </template>
      <template v-else-if="state.step === 3">
        <!-- step3 同梱資材SKU選択 -->
        <h4>ラッピング資材のサイズを選択してください</h4>
        <div :class="$style.warning_message">
          <p> ※ この商品以外の商品もまとめてラッピングをされる方は、推奨サイズよりも大きいサイズをお選びください </p>
        </div>
        <div :class="$style.gift_item">
          <div :class="$style.wrapping_list">
            <div
              v-for="(sku, index) in selectedGiftSkus()"
              :key="sku.document_id"
              :class="[$style.wrapping, { [$style.is_out_of_stock]: isOutOfStock(sku) }]"
              @click="selectedIncludeSku(sku)"
            >
              <div
                :class="[$style.wrapping_img, { [$style.selected]: sku.document_id === state.includeSku?.document_id }]"
              >
                <AtomsConsumerImage :src="sku.main_image ?? ''" :width="184" :height="245" />
                <AtomsConsumerButton :class="$style.expansion" @click="openGiftImageModal($event, index)">
                  <AtomsConsumerImage src="/images/common/cart/zoom.svg" alt="" :class="$style.zoom" />
                </AtomsConsumerButton>
                <span
                  v-if="currentGiftWrapping?.wrappingSku.document_id === sku.document_id"
                  :class="$style.recommend_size"
                >
                  推奨サイズ
                </span>
              </div>
              <div :class="$style.wrapping_info">
                <p :class="$style.title">
                  {{ currentGiftWrapping?.title }} {{ sku.size }}
                  <template v-if="sku.size_supplement">（{{ sku.size_supplement }}）</template>
                </p>
                <div v-if="isOutOfStock(sku)" :class="$style.out_of_stock">
                  <p :class="$style.label">在庫切れ</p>
                  <p v-if="sku && sku.shipping_supplement" :class="$style.supplement">{{ sku.shipping_supplement }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="$style.btn_area">
          <!-- 同梱はシール選択スキップ -->
          <AtomsConsumerButton variant="primary" block :disabled="!state.includeSku" @click="nextStep(3)">
            次へ
          </AtomsConsumerButton>
          <AtomsConsumerButton variant="secondary" block @click="prevStep(3)"> 戻る </AtomsConsumerButton>
        </div>
      </template>
      <template v-else-if="state.step === 4">
        <!-- step4 シール -->
        <h4>シールを選択してください</h4>
        <div :class="$style.message_container">
          <div v-for="(message, index) in giftMessages" :key="message.document_id" :class="$style.item">
            <AtomsConsumerButton
              :class="{ _selected: message.product?.skus[0].document_id === state.tempSelectionGift.message_sku_id }"
              @click="selectedMessage(index)"
            >
              <AtomsConsumerImage :src="message.img ?? ''" :width="184 * 2" :height="245 * 2" />
            </AtomsConsumerButton>
            <div :class="$style.wrapping_info">
              <p :class="$style.title">{{ message.title }}</p>
              <p v-if="message.commission_price != 0" :class="$style.price">
                <span :class="$style.tax_include">税込</span>&yen;{{
                  message.commission_price ? message.commission_price.toLocaleString() : ""
                }}
              </p>
              <p v-else :class="$style.price">無料</p>
            </div>
          </div>
          <div
            :class="[$style.item, $style.unspecified, { [$style.selected]: !state.tempSelectionGift.message_sku_id }]"
            @click="initMessage()"
          >
            <p :class="[$style.unspecified_text, { [$style.selected]: !state.tempSelectionGift.message_sku_id }]">
              指定しない
            </p>
          </div>
        </div>
        <div :class="$style.btn_area">
          <!-- シールは未指定でも進める -->
          <AtomsConsumerButton variant="primary" block @click="nextStep(4)">次へ</AtomsConsumerButton>
          <AtomsConsumerButton variant="secondary" block @click="prevStep(4)">戻る</AtomsConsumerButton>
        </div>
      </template>
      <template v-else-if="state.step === 5">
        <!-- step5 メッセージカード -->
        <h4>カードを選択してください</h4>
        <div :class="$style.warning_message">
          <p> ※ メッセージカードへお客様の個別のメッセージを印刷することはできません </p>
        </div>
        <div :class="$style.card_container">
          <div v-for="(card, index) in giftCards" :key="card.document_id" :class="$style.item">
            <AtomsConsumerButton
              :class="{
                [$style.selected]: card.wrappingSku.document_id === state.tempSelectionGift.card_sku_id,
                [$style.is_out_of_stock]: card.isOutOfStock,
              }"
              @click="selectedCard(index)"
            >
              <AtomsConsumerImage :src="card.img ?? ''" :alt="card.title" :width="184" :height="245" />
            </AtomsConsumerButton>
            <div :class="$style.wrapping_info">
              <p :class="$style.title">{{ card.title }}</p>
              <div v-if="card.isOutOfStock" :class="$style.out_of_stock">
                <p :class="$style.label">在庫切れ</p>
                <p v-if="card.wrappingSku && card.wrappingSku.shipping_supplement" :class="$style.supplement">{{
                  card.wrappingSku.shipping_supplement
                }}</p>
              </div>
              <p v-if="card.commission_price != 0" :class="$style.price">
                <span :class="$style.tax_include">税込</span>&yen;{{
                  card.commission_price ? card.commission_price.toLocaleString() : ""
                }}
              </p>
              <p v-else :class="$style.price">無料</p>
            </div>
          </div>
          <div
            :class="[$style.item, $style.unspecified, { [$style.selected]: !state.tempSelectionGift.card_sku_id }]"
            @click="initCard()"
          >
            <p :class="[$style.unspecified_text, { [$style.selected]: !state.tempSelectionGift.card_sku_id }]">
              指定しない
            </p>
          </div>
        </div>
        <div :class="$style.btn_area">
          <span v-if="isEmbroideryItem" :class="$style.description">
            ※刺繍サービスをご利用の際は、メッセージカードの指定は承れません。
          </span>

          <!-- シールは未指定でも進める -->
          <AtomsConsumerButton
            variant="primary"
            block
            :disabled="isRequiredCardSelection && !state.tempSelectionGift.card_sku_id"
            @click="nextStep(5)"
          >
            次へ
          </AtomsConsumerButton>
          <AtomsConsumerButton v-if="isCardOnly" variant="secondary" block @click="() => emit('cancel')">
            閉じる
          </AtomsConsumerButton>
          <AtomsConsumerButton v-else variant="secondary" block @click="prevStep(5)"> 戻る </AtomsConsumerButton>
        </div>
      </template>
      <!-- TODO: step6 ショッパー -->
      <!-- step9 確認 -->
      <template v-else-if="state.step === 9">
        <h4>
          {{
            isCardOnly ? "メッセージカード" : giftCards.length > 0 ? "ギフトラッピング・カード" : "ギフトラッピング"
          }}設定の確認
        </h4>
        <div :class="$style.confirm_section">
          <div v-if="state.wrappingCheckItem === 'wrapping'" :class="$style.confirm_item">
            <!-- ラッピング -->
            <div :class="$style.image_wrap">
              <AtomsConsumerImage :src="currentGiftWrapping?.img ?? ''" :width="184" :height="245" />
            </div>
            <div :class="$style.wrapping_info">
              <p>{{ currentGiftWrapping?.title }}</p>
              <p v-if="currentGiftWrapping?.commission_price != 0" :class="$style.price">
                <span :class="$style.tax_include">税込</span>&yen;{{
                  currentGiftWrapping?.commission_price ? currentGiftWrapping?.commission_price.toLocaleString() : ""
                }}
              </p>
              <p v-else :class="$style.price">無料</p>
            </div>
          </div>
          <div v-else-if="state.wrappingCheckItem === 'include' && state.includeSku" :class="$style.confirm_item">
            <!-- 同梱 -->
            <div :class="$style.image_wrap">
              <AtomsConsumerImage :src="state.includeSku.main_image ?? ''" :width="184" :height="245" />
            </div>
            <div :class="$style.wrapping_info">
              <p>
                {{ currentGiftWrapping?.title }} {{ state.includeSku.size }}
                <template v-if="state.includeSku.size_supplement">
                  （{{ state.includeSku.size_supplement }}）
                </template>
              </p>
              <p v-if="currentGiftWrapping?.commission_price != 0" :class="$style.price">
                <span :class="$style.tax_include">税込</span>&yen;{{
                  currentGiftWrapping?.commission_price ? currentGiftWrapping?.commission_price.toLocaleString() : ""
                }}
              </p>
              <p v-else :class="$style.price">無料</p>
            </div>
          </div>
          <div v-if="state.tempSelectionGift.message_sku_id" :class="$style.confirm_item">
            <div :class="$style.image_wrap">
              <AtomsConsumerImage :src="currentGiftMessage?.img ?? ''" :width="184" :height="245" />
            </div>
            <div :class="$style.wrapping_info">
              <p>{{ currentGiftMessage?.title }}</p>
              <p v-if="currentGiftMessage?.commission_price != 0" :class="$style.price">
                <span :class="$style.tax_include">税込</span>&yen;{{
                  currentGiftMessage?.commission_price ? currentGiftMessage?.commission_price.toLocaleString() : ""
                }}
              </p>
              <p v-else :class="$style.price">無料</p>
            </div>
          </div>
          <div v-if="state.tempSelectionGift.card_sku_id" :class="$style.confirm_item">
            <div :class="$style.image_wrap">
              <AtomsConsumerImage :src="currentGiftCard?.img ?? ''" :width="184" :height="245" />
            </div>
            <div :class="$style.wrapping_info">
              <p>{{ currentGiftCard?.title }}</p>
              <p v-if="currentGiftCard?.commission_price != 0" :class="$style.price">
                <span :class="$style.tax_include">税込</span>&yen;{{
                  currentGiftCard?.commission_price ? currentGiftCard?.commission_price.toLocaleString() : ""
                }}
              </p>
              <p v-else :class="$style.price">無料</p>
            </div>
          </div>
          <div :class="$style.btn_area">
            <div :class="$style.total_price">
              <div>合計</div>
              <div v-if="totalGiftPrice()" :class="$style.price">
                <span :class="$style.tax_include">税込</span>&yen;{{ totalGiftPrice() }}
              </div>
              <div v-else :class="$style.price">無料</div>
            </div>

            <AtomsConsumerButton
              v-if="state.wrappingCheckItem === 'include'"
              variant="primary"
              block
              @click="addCartIncludeSku"
            >
              同梱資材をカートに追加
            </AtomsConsumerButton>
            <AtomsConsumerButton
              v-else
              variant="primary"
              block
              :disabled="!state.wrappingCheckItem"
              @click="() => emit('confirm', state.tempSelectionGift, isRequiredCardSelection)"
            >
              {{ state.wrappingCheckItem === "card" ? "カードを設定する" : "ギフトの設定を完了する" }}
            </AtomsConsumerButton>
            <AtomsConsumerButton variant="secondary" block @click="prevStep(9)"> 戻る </AtomsConsumerButton>
          </div>
        </div>
      </template>
    </div>
    <!-- 資材画像モーダル -->
    <MoleculesConsumerModal v-model="displayGiftImageRef" :class="$style.gift_sku_preview_modal">
      <Swiper
        :modules="[SwiperNavigation]"
        instance-name="gift-slider"
        :class="$style.swiper_container"
        :navigation="{
          nextEl: $style.swiper_button_prev,
          prevEl: $style.swiper_button_next,
        }"
      >
        <SwiperSlide v-for="image in modalImageRef" :key="image" :class="$style.swiper_slide">
          <AtomsConsumerImage :src="image" alt="" :width="184 * 3" :height="245 * 3" />
        </SwiperSlide>
        <div :class="$style.swiper_button_prev"></div>
        <div :class="$style.swiper_button_next"></div>
      </Swiper>
    </MoleculesConsumerModal>
  </div>
</template>

<script setup lang="ts">
import { CartGift } from "@tential/ec-gql-schema/models/order"
import { ProductAndSku } from "@tential/ec-gql-schema/types/cart"
import { cloneDeep } from "~/utils/functions/common"
import { useToast } from "~/state"
import { useCart } from "~/composables/useCart"
import { GiftPatternWithSku } from "~/types/cart"
import { SkuFragmentFragment } from "~/types/type.generated"
import { SELECT_GIFT_INIT_DATA } from "~/const/product"
import { VISIBLE_SELF_WRAPPING_PAGE, SELF_WRAPPING_PAGE_URL } from "~/const/index"
import { priceWithComma } from "~/utils/functions/price"
import { GiftPattern } from "~/types/server/giftPattern"

type Props = {
  giftWrappings?: GiftPatternWithSku[]
  giftMessages?: GiftPattern[]
  giftCards?: GiftPatternWithSku[]
  cartDetail?: ProductAndSku
  isCardOnly?: boolean
  isEmbroideryConfirm?: boolean
  isEmbroideryItem?: boolean
  isFromEmbroidery?: boolean
  isEmbroideryProductsInCart?: boolean
  isEGift?: boolean
}
interface State {
  wrappingCheckItem: string
  step: number
  includeSku: SkuFragmentFragment | null
  tempSelectionGift: CartGift
}

const toast = useToast()
const cart = useCart()
const route = useRoute()
const displayGiftImageRef = ref<boolean>(false)
const modalImageRef = ref<string[]>([])

type Emits = {
  (e: "confirm", tempSelectionGift: CartGift, isCard: boolean): void
  (e: "cancel"): void
}

const props = withDefaults(defineProps<Props>(), {
  /** 選択可能なラッピング資材 */
  giftWrappings: () => [],
  /** 選択可能なシール */
  giftMessages: () => [],
  /** 選択可能なカード */
  giftCards: () => [],
  /** カート内商品 */
  cartDetail: undefined,
  /** カードのみラッピングできる商品 */
  isCardOnly: false,
  isEmbroideryConfirm: false,
  isEmbroideryItem: false,
  isFromEmbroidery: false,
  isEmbroideryProductsInCart: false,
  isEGift: false,
})

const state: State = reactive({
  /** 選択中のラッピング方法 */
  wrappingCheckItem: props.isCardOnly ? "card" : "wrapping",
  /** 現在のステップ（ラッピング済み商品はstep5からスタート） */
  step: props.isCardOnly ? 5 : 1,
  /** 選択中の同梱資材SKU */
  includeSku: null,
  /** 選択中のラッピング情報 */
  tempSelectionGift: cloneDeep(SELECT_GIFT_INIT_DATA),
})

const wrappingCheckItems = computed(() => {
  const items = [{ key: "ラッピングして購入", value: "wrapping" }]

  if (isNotEmbroidery.value) {
    items.push({ key: "ラッピング資材を購入（セルフラッピング）", value: "include" })

    if (props.giftCards.length > 0) {
      items.push({ key: "メッセージカードのみ購入", value: "card" })
    }
  }

  return items
})

const isNotEmbroidery = computed(() => {
  return !props.isEmbroideryConfirm && !props.isFromEmbroidery && !props.isEmbroideryProductsInCart
})

watch(
  () => [props.isCardOnly, isNotEmbroidery.value],
  ([isCardOnly, isNotEmbroidery]) => {
    if (isCardOnly && isNotEmbroidery) {
      state.wrappingCheckItem = "card"
    }
  },
)

// 選択中のラッピング資材・シール
const currentGiftWrapping = computed(() =>
  props.giftWrappings.find((wrapping) => wrapping.wrappingSku.document_id === state.tempSelectionGift?.sku_id),
)
const currentGiftMessage = computed(() =>
  props.giftMessages.find((message) => message.document_id === state.tempSelectionGift?.message_sku_id),
)
const currentGiftCard = computed(() =>
  props.giftCards.find((card) => card.wrappingSku.document_id === state.tempSelectionGift?.card_sku_id),
)

// カードの選択が必須か
const isRequiredCardSelection = computed(() => props.isCardOnly || state.wrappingCheckItem === "card")

const isOutOfStock = (sku: SkuFragmentFragment | null) => {
  return sku ? isSkuOutOfStock(sku) : true
}

const selectedGiftSkus = (): SkuFragmentFragment[] => {
  return currentGiftWrapping.value?.product?.skus || []
}

const openGiftImageModal = (event: Event, index: number) => {
  event.preventDefault()
  displayGiftImageRef.value = true
  if (state.wrappingCheckItem === "include") {
    // 同梱の場合
    const selectedGift = currentGiftWrapping.value
    // メイン画像を追加
    const mainImage = selectedGift?.product?.skus[index].main_image ?? ""
    // サブイメージを追加
    const subImages = selectedGift?.product?.skus[index].sub_images.map((image) => image?.image_url ?? "") ?? []
    const modalImages = [mainImage].concat(subImages)
    // 空文字を除外
    modalImageRef.value = modalImages.filter((image) => image !== "")
  } else {
    // ラッピングの場合
    // メイン画像を追加
    const mainImage = props.giftWrappings[index].img ?? ""
    // サブイメージを追加
    const subImages = props.giftWrappings[index].wrappingSku.sub_images.map((image) => image?.image_url ?? "")
    const modalImages = [mainImage].concat(subImages)
    // 空文字を除外
    modalImageRef.value = modalImages.filter((image) => image !== "")
  }
}

const totalGiftPrice = (): number => {
  const wrappingPrice = currentGiftWrapping.value?.commission_price || 0
  const messagePrice = currentGiftMessage.value?.commission_price || 0
  const cardPrice = currentGiftCard.value?.commission_price || 0
  return wrappingPrice + messagePrice + cardPrice
}

// 同梱資材SKU選択
const selectedIncludeSku = (sku: SkuFragmentFragment) => {
  state.includeSku = sku
}

/** 資材のみ単品購入 */
const addCartIncludeSku = async () => {
  const product = currentGiftWrapping.value?.product
  const sku = state.includeSku
  if (!product || !sku) {
    toast?.showErrorToasted("ラッピング資材をカートに追加できませんでした")
    return
  }
  try {
    // 資材をカートに追加
    await cart.addCartItem({
      product_id: product.document_id,
      product_name: product.name,
      sku_id: sku.document_id,
      site_id: sku.site_id || undefined,
      sku_name: sku.name,
      is_reservation: false,
      material: product.material || undefined,
      net_stock_quantity: sku.net_stock_quantity || 0,
      net_stock_quantity_limit: sku.net_stock_quantity_limit || 0,
      is_bundled_wrapping: product.is_gift || product.cart_only_gift,
      is_wrapping_product: false,
    })
    // カードをカートに追加
    const cardProduct = currentGiftCard.value?.product
    const cardSku = cardProduct?.skus[0]
    if (cardProduct && cardSku) {
      await cart.addCartItem({
        product_id: cardProduct.document_id,
        product_name: cardProduct.name,
        sku_id: cardSku.document_id,
        site_id: cardSku.site_id || undefined,
        sku_name: cardSku.name,
        is_reservation: false,
        material: cardProduct.material || undefined,
        net_stock_quantity: cardSku.net_stock_quantity || 0,
        net_stock_quantity_limit: cardSku.net_stock_quantity_limit || 0,
        is_bundled_wrapping: cardProduct.is_gift || cardProduct.cart_only_gift,
        is_wrapping_product: false,
      })
    }
  } catch {
    toast?.showErrorToasted("ラッピング資材をカートに追加できませんでした")
    return
  }

  toast?.showSuccessToasted("ラッピング資材をカートに追加しました")
  emit("cancel")
  if (!route.fullPath.includes("/order/new")) {
    cart.openCart()
  }
}

// 次のステップへ
const nextStep = (nowStep: 1 | 2 | 3 | 4 | 5) => {
  switch (nowStep) {
    case 1:
      // ラッピング有無選択
      // 選択をリセット
      initWrapping()
      initMessage()
      initCard()
      if (state.wrappingCheckItem === "card") state.step = 5
      else {
        state.step = 2
        // 1つ目のラッピングを自動で選択
        selectedWrapping(0)
      }
      break
    case 2:
      // 資材選択
      if (state.wrappingCheckItem === "include" && isNotEmbroidery.value) {
        state.step = 3
        // 推奨サイズを自動で選択
        const sku = currentGiftWrapping.value?.wrappingSku
        if (sku) selectedIncludeSku(sku)
      } else if (props.giftMessages.length > 0 && isNotEmbroidery.value) state.step = 4
      else if (props.giftCards.length > 0 && isNotEmbroidery.value) state.step = 5
      else state.step = 9
      break
    case 3:
      // 同梱資材SKU選択
      if (props.giftCards.length > 0) state.step = 5
      // 同梱の場合はシール選択スキップ
      else state.step = 9
      break
    case 4:
      // シール
      if (props.giftCards.length > 0) state.step = 5
      else state.step = 9
      break
    case 5:
      // カード
      if (props.isCardOnly) initWrapping()
      state.step = 9
      break
  }
}

// 前のステップへ
const prevStep = (nowStep: 2 | 3 | 4 | 5 | 9) => {
  switch (nowStep) {
    case 2:
      // 資材選択
      state.step = 1
      // シール・カード選択をリセット
      initWrapping()
      initMessage()
      initCard()
      break
    case 3:
    case 4:
      // 同梱資材SKU選択・シール
      state.step = 2
      break
    case 5:
      // カード
      if (state.wrappingCheckItem === "card" && isNotEmbroidery.value) {
        state.step = 1
        // 選択をリセット
        initWrapping()
        initMessage()
        initCard()
      } else state.step = 2
      break
    case 9:
      // 確認
      if (isRequiredCardSelection && isNotEmbroidery.value) state.step = 5
      else state.step = 2
      break
  }
}

// ラッピング資材選択
const selectedWrapping = (index: number): void => {
  const wrapping = props.giftWrappings[index]
  if (wrapping.isOutOfStock) {
    toast?.showErrorToasted("ラッピング資材が在庫切れのため選択出来ません")
    return
  }
  if (!wrapping.product) return
  state.tempSelectionGift.sku_id = wrapping.wrappingSku.document_id || ""
  state.tempSelectionGift.size = wrapping.wrappingSku.size || ""
  state.tempSelectionGift.price = wrapping.commission_price || 0
  state.tempSelectionGift.sku_name = wrapping.wrappingSku.name || ""
  state.tempSelectionGift.sku_code = wrapping.wrappingSku.sku_code || ""
  state.tempSelectionGift.img = wrapping.wrappingSku.main_image || ""
  state.tempSelectionGift.product_id = wrapping.wrappingSku.product_id || ""
  state.tempSelectionGift.product_name = wrapping.product.name || ""
}

// シール選択
const selectedMessage = (index: number): void => {
  const message = props.giftMessages[index]
  if (!message.product) return
  state.tempSelectionGift.message_sku_id = message.product.skus[0].document_id || ""
  state.tempSelectionGift.message_sku_code = message.product.skus[0].sku_code || ""
  state.tempSelectionGift.message = message.title || ""
  state.tempSelectionGift.message_img = message.img || ""
  state.tempSelectionGift.message_price = message.commission_price || 0
}

// カード選択
const selectedCard = (index: number): void => {
  const card = props.giftCards[index]
  if (!card.product) return
  state.tempSelectionGift.card_sku_id = card.product.skus[0].document_id || ""
  state.tempSelectionGift.card_sku_code = card.product.skus[0].sku_code || ""
  state.tempSelectionGift.card = card.title || ""
  state.tempSelectionGift.card_img = card.img || ""
  state.tempSelectionGift.card_price = card.commission_price || 0
}

const initWrapping = (): void => {
  state.tempSelectionGift.sku_id = ""
  state.tempSelectionGift.size = ""
  state.tempSelectionGift.price = 0
  state.tempSelectionGift.sku_name = ""
  state.tempSelectionGift.sku_code = ""
  state.tempSelectionGift.img = ""
  state.tempSelectionGift.product_id = ""
  state.tempSelectionGift.product_name = ""
}

const initMessage = (): void => {
  state.tempSelectionGift.message_sku_id = ""
  state.tempSelectionGift.message_sku_code = ""
  state.tempSelectionGift.message = ""
  state.tempSelectionGift.message_img = ""
  state.tempSelectionGift.message_price = 0
}

const initCard = (): void => {
  state.tempSelectionGift.card_sku_id = ""
  state.tempSelectionGift.card_sku_code = ""
  state.tempSelectionGift.card = ""
  state.tempSelectionGift.card_img = ""
  state.tempSelectionGift.card_price = 0
}

const emit = defineEmits<Emits>()
</script>

<style module lang="scss">
.select_gift_modal {
  .gift_section {
    text-align: left;
    max-width: $max-width-sm;
    width: calc(100vw - 2rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    @include md {
      padding: 2rem 4rem;
    }
    .modal_title {
      color: $primary;
      margin-bottom: 1rem;
    }
    h4 {
      text-align: left;
      color: $primary;
      padding: 1rem 0;
    }
    img:not(.zoom) {
      @include background-skeleton-loading;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 0.375rem;
    }
    .title {
      font-size: $font-size-14;
    }
    .price {
      @include main-font;
      font-size: $font-size-15;
      .tax_include {
        font-size: $font-size-12;
      }
    }
    .warning_message {
      color: $danger;
      text-align: left;
      padding: 0.5rem;
      font-size: $font-size-14;
      line-height: 1.8;
    }
    .wrapping_info {
      margin: 0.5rem 0;
      > p {
        line-height: 1.5;
      }
    }
    .product_box {
      display: flex;
      gap: 1rem;
      .right_container {
        .image_container {
          // width: 45%;
          display: flex;
          gap: 0.5rem;
          align-items: center;
          > img {
            width: 72px;
            height: 96px;
            // width: 100%;
            object-fit: cover;
            border-radius: 6px;
          }
          input[type="checkbox"] {
            // デフォルトのスタイルを消す
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            padding: 0;
            margin-right: 0.5rem;
            position: relative;
            width: 1.25rem;
            height: 1.25rem;
            border: 1px solid;
            border-radius: 0.25rem;
            border-color: $primary-20;
            bottom: 1px;

            &:checked {
              border-color: $primary-40;
              background-color: $secondary-10;
            }

            // チェックマークを生成
            &:checked:before {
              position: absolute;
              top: 0.1875rem;
              left: 0.375rem;
              transform: rotate(50deg);
              width: 0.375rem;
              height: 0.625rem;
              border-right: 2px solid $secondary;
              border-bottom: 2px solid $secondary;
              content: "";
            }
          }
        }
      }
      .detail_container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .name_sku {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          .name {
            @include main-font;
            font-size: $font-size-14;
            font-weight: bold;
          }
          .sku {
            font-size: $font-size-12;
            line-height: 1.5;
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            @include md {
              line-height: inherit;
            }
            .e_gift {
              line-height: 1rem;
              font-size: $font-size-10;
              @include md {
                line-height: inherit;
              }
            }
          }
        }
        .product_price {
          .price {
            @include main-font;
            font-size: $font-size-20;
            span {
              font-size: $font-size-10;
              @include md {
                font-size: $font-size-12;
              }
            }
          }
          .strike {
            font-size: $font-size-10;
            text-decoration: line-through;
            @include md {
              font-size: $font-size-12;
            }
          }
          .discounted,
          .discount_announce {
            color: $red;
          }
        }
      }
    }
    .select_area {
      display: flex;
      flex-direction: column;
      text-align: left;
      gap: 0.75rem;
    }
    .gift_item {
      width: 100%;
      .wrapping_list {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        .wrapping {
          width: calc(50% - 0.5rem);
          cursor: pointer;
          > .wrapping_img {
            display: inline-block;
            position: relative;
            top: 0;
            width: 100%;
            background-color: #eee;
            border: 2px solid #fff;
            border-radius: 0.375rem;
            aspect-ratio: 184 / 245;
            &.selected {
              border-color: $primary;
            }
            .expansion {
              position: absolute;
              bottom: 0.5rem;
              right: 0.5rem;
              padding: 0.5rem;
              height: 2rem;
              width: 2rem;
              filter: brightness(0.4);
            }
            .recommend_size {
              position: absolute;
              top: 0.5rem;
              right: 0.5rem;
              padding: 0 0.5rem;
              border-radius: 1rem;
              font-size: $font-size-12;
              background-color: $black-10;
              color: $font-color-primary;
            }
          }
        }
      }
    }
    .card_container,
    .message_container {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;
      width: 100%;
      .item {
        width: calc(50% - 0.5rem);
        cursor: pointer;
        &.unspecified {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $secondary-40;
          border-radius: 0.375rem;
          color: $secondary;
          height: max-content;
          aspect-ratio: 1 / 1;
          &.selected {
            border-color: $primary;
            border-width: 2px;
          }
        }
        :global(button) {
          width: 100%;
          transition: all 0.3s;
          background-color: #eee;
          border: 2px solid #fff;
          border-radius: 0.375rem;
          aspect-ratio: 1 / 1;
          &.selected {
            border-color: $primary;
          }
        }
      }
    }
    .confirm_section {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      text-align: left;
      width: 100%;
      .confirm_item {
        display: flex;
        align-items: center;
        gap: 1rem;
        .image_wrap {
          width: 120px;
          height: auto;
        }
        .wrapping_info {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
      .total_price {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        margin-bottom: 2rem;
        border-top: 1px solid $primary-20;
        .price {
          font-size: $font-size-16;
        }
      }
    }
    .btn_area {
      position: sticky;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.5rem;
      bottom: -3rem;
      z-index: 10;
      padding-top: 1rem;
      padding-bottom: 1rem;
      background: linear-gradient(#ffffff00, #ffffffaa 20%);
      .description {
        font-size: $font-size-11;
        color: $text-gray;
        .self_wrapping,
        .explanation {
          display: inline-block;
          font-size: $font-size-11;
          color: $text-gray;
          border-bottom: 1px solid $text-gray;
        }
      }
    }
    .is_out_of_stock {
      opacity: 0.5;
      pointer-events: none;
    }
    .out_of_stock {
      display: inline-block;
      .label {
        background-color: $black-10;
        color: $font-color-primary;
        width: fit-content;
        margin: 0 auto 5px;
        padding: 0 0.5rem;
        font-size: $font-size-13;
      }
      .supplement {
        font-size: $font-size-12;
      }
    }
  }
  .gift_sku_preview_modal {
    z-index: 101 !important;
  }
}
</style>
